<template>

    <div class="h-auto rounded-lg bg-filters p-2 mb-2">

        <div class="h-5 flex flex-row justify-start items-center">
            <div class="h-full w-1 rounded-full bg-purple mr-2" :style="'background-color:' + legendColors[data.id].color + ';'"></div>
            <span class="text-white font-semibold text-xs">{{ data.name }}</span>
            <div class="h-6 w-6 rounded-md flex flex-col justify-center items-center cursor-pointer ml-auto" :class="getOpacity(data.id)" @click="toggleSlSelectedItems(data.id)">
                <i class="mdi mdi-ray-start-arrow text-xs"></i>
            </div>
        </div>

        <div class="h-8 mt-1 flex flex-col">
            <div class="flex-1 flex flex-row justify-start items-center">
                <span class="text-3xs text-font-gray font-semibold mr-1">Sales</span>
                <span class="text-xxs text-white font-semibold mr-auto">{{ data.total_sales | reduceBigNumbers(2) }}€</span>
                <span class="text-3xs text-font-gray mr-1">vs Obj</span>
                <span class="text-3xs text-font-gray font-semibold mr-auto"
                      :class="successOrDangerClass(data.objective_sale,100)"
                ><span v-if="data.objective_sale>0">+</span>{{ data.objective_sale | reduceBigNumbers(2) }}<span v-if=" isNotNaAndTbd(data.objective_sale)">%</span></span>
                <span class="text-3xs text-font-gray mr-1">VsAvg (SL)</span>
                <span class="text-3xs font-semibold"><span v-if=" data.avg_sale>0">+</span>{{ data.avg_sale | reduceBigNumbers(2) }} <span v-if="isNotNaAndTbd(data.avg_sale)">ptos.</span></span>
            </div>
            <div class="h-2 mb-1 flex flex-row justify-center">
                <div class="h-2px w-full rounded-full bg-body overflow-hidden relative">
                  <div class="h-full absolute bg-blue z-10 top-0 left-0"
                       :style="barWidth(data.objective_sale)"></div>
                  <div class="h-full bg-success absolute top-0 left-0"
                       :style="barWidth(data.avg_sale)"></div>
                </div>
            </div>
        </div>

        <div class="h-8 mt-1 flex flex-col">
            <div class="flex-1 flex flex-row justify-start items-center">
                <span class="text-3xs text-font-gray font-semibold mr-1">Sales Estimated</span>
                <span class="text-xxs text-white font-semibold mr-auto">{{ data.estimated_sales | reduceBigNumbers(2) }}€</span>
                <span class="text-3xs text-font-gray mr-1">vs Obj</span>
                <span class="text-3xs text-font-gray font-semibold"><span v-if="data.objective_estimated_sales>0"></span>{{ data.objective_estimated_sales | reduceBigNumbers(2) }}<span v-if="isNotNaAndTbd(data.objective_sale)">%</span></span>
            </div>
            <div class="h-2 mb-1 flex flex-row justify-center">
                <div class="h-2px w-full rounded-full bg-body overflow-hidden relative">
                    <div class="h-full  absolute bg-warning top-0 left-0"
                         :style="barWidth(data.objective_estimated_sales)"></div>
                </div>
            </div>
        </div>

        <div class="h-8 mt-1 flex flex-col">
            <div class="flex-1 flex flex-row justify-start items-center">
                <span class="text-3xs text-font-gray font-semibold mr-auto">Effectiveness ratio</span>
                <span class="text-xxs text-white font-semibold">{{ data.effectiveness_ratio | reduceBigNumbers(2) }}%</span>
            </div>
            <div class="h-2 mb-1 flex flex-row justify-center">
                <div class="h-2px w-full rounded-full bg-body overflow-hidden relative">
                    <div class="h-full absolute bg-danger top-0 left-0"
                         :style="'width:' + data.effectiveness_ratio + '%'"></div>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
import { state,actions } from '@/store';

export default {
    name: 'PipelineSl',
    props: ['data'],
    data() {
        return { }
    },
    computed: {
        legendColors() { return state.slLegendColors },
        slSelectedItems(){
            return state.slSelectedItems
        }
    },
    methods:{
        getOpacity(k) {

                if (this.slSelectedItems.includes(k)) {
                return 'bg-aux-15 text-aux'
            } else {
                return 'bg-font-gray-15 text-font-gray'
            }

        },
        barWidth(val){
          if (this.isNotNaAndTbd(val)===false){
            return 'width:100%';
          }
          return  'width:' + val + '%';
        },
        toggleSlSelectedItems(item) {


            if(!this.slSelectedItems.includes(item)){
                actions.toggleSlSelectedItems(item)
                this.$emit('nextPage')
            } else {
                actions.toggleSlSelectedItems(item)
            }
        }
    },
    mounted() {
    }
}
</script>